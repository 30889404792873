import _ from 'lodash';
import linkifyHtml from 'linkifyjs/html';
import moment from 'moment';

import IsoMorphUtils from './isoMorphUtils';

export function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }
  return arr.slice(1).reduce(function(xs, x, i) {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function simpleFormat(_str) {
  if (!_str) return;
  let str = _str.replace(/\r\n?/, '\n');
  str = str.trim();
  str = escapeHtml(str);
  str = linkifyHtml(str, {});
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, '</p><p>');
    str = str.replace(/\n/g, '<br />');
    str = `<p>${str}</p>`;
  }
  return str;
}

export function isMobile() {
  if (typeof window === 'object' && window.innerWidth <= 900) {
    return true;
  }
  return false;
}

export function formatPhoneNumber(rawNumber) {
  const s2 = (`${rawNumber}`).replace(/\D/g, '');
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  let result = (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
  if (!result) {
    result = rawNumber;
  }
  return result;
}

export function formatDisplayTime(time) {
  let displayTime = moment(time);
  if (displayTime.format('mm') == '00') {
    displayTime = displayTime.format('ha');
  } else {
    displayTime = displayTime.format('h:mma');
  }
  return displayTime;
}

export function numberWithCommas(x) {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cityState(address) {
  if (!address) return null;
  const addressArray = address.trim().split(',');
  return addressArray[addressArray.length - 3] + ", " + addressArray[addressArray.length - 2];
}

export function fixBodyScroll() {
  let scrollY = 0;
  if (window.scrollY > 0) {
    scrollY = window.scrollY;
  }
  document.body.style = `width: 100%; position: fixed; top: ${-scrollY}px;`
  return scrollY;
}

export function unfixBodyScroll(scrollY = 0) {
  document.body.style = '';
  window.scrollTo(0, scrollY);
}

export function getUrl() {
  if (IsoMorphUtils.serverSide) {
    return `${IsoMorphUtils.railsContext.scheme}:${IsoMorphUtils.railsContext.href}`;
  }
  return  window.location.href;
}